<template>
  <div
    v-if="$can('read', 'ModuleBooking.Contract')"
    style="height: inherit"
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <!-- Filters -->
      <contract-list-filters
        :collapsed-filter.sync="collapsedFilter"
        :event-code-filter.sync="eventCodeFilter"
        :description-filter.sync="descriptionFilter"
        :status-filter.sync="statusFilter"
        :status-options.sync="statusOptions"
        @reset-filter="resetFilter"
      />

      <!-- Table Container Card -->
      <b-card
        no-body
      >
        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              sm="12"
              md="6"
            >
              <label>{{ $t('Entries') }}</label>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              sm="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end w-100 mt-1 mt-md-0">
                <b-button
                  v-if="$can('create', 'ModuleBooking.Contract')"
                  variant="primary"
                  class="w-50"
                  @click="addContractForm"
                >
                  {{ `${$t('Add')} ${$t('FormContract.Contract')}` }}
                </b-button>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1 ml-1"
                  :placeholder="`${$t('Search')}...`"
                />
              </div>
            </b-col>
          </b-row>

        </div>
        <b-table
          ref="refContractListTable"
          :items="getListContract"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          hover
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
          selectable
          select-mode="multi"
        >
          <!-- Column: id -->
          <template #cell(id)="data">
            <!-- ID -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('ID') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="(skin === 'dark')? `light-info` : `light-info` "
                      class="text-capitalize"
                    >
                      {{ data.item.id }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Event Type -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; background-color: #eeeeee;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormContract.Type') }} »
                  </span>
                  <span style="font-size: 18px;">
                    <b-badge
                      pill
                      :variant="(data.item.event_type === 'booking')? `light-info` : `light-success`"
                    >
                      {{ $t(textFirstUpper(data.item.event_type)) }}
                    </b-badge>
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  v-if="data.item.event_type === 'booking' && data.item.booking !== undefined"
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormBooking.BookingCode') }} »
                  </span>
                  <span style="font-size: 18px;">
                    <b-badge
                      pill
                      variant="light-primary"
                    >
                      {{ data.item.booking.booking_code }}
                    </b-badge>
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  v-if="data.item.event_type === 'booking' && data.item.booking !== undefined"
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormBooking.ProductName') }} »
                  </span>
                  <span style="font-size: 14px;">
                    {{ data.item.booking.name }}
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  v-if="data.item.event_type === 'booking' && data.item.booking !== undefined"
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    <b-badge
                      pill
                      variant="light-success"
                    >
                      {{ data.item.booking.product_type.name }}
                    </b-badge> »
                  </span>
                  <span style="font-size: 14px;">
                    {{ data.item.booking.category_name }}
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  v-if="data.item.event_type === 'booking' && data.item.booking !== undefined"
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">
                    {{ data.item.booking.department_name }}
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  v-if="data.item.event_type === 'booking' && data.item.booking !== undefined"
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Date') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <feather-icon icon="CalendarIcon" />
                    {{ `${data.item.booking.start_date.name} - ${data.item.booking.end_date.name}` }}
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  v-if="data.item.event_type === 'booking' && data.item.booking !== undefined"
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Time') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <feather-icon icon="ClockIcon" />
                    {{ `${data.item.booking.start_time} - ${data.item.booking.end_time}` }}
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  v-if="data.item.event_type === 'booking' && data.item.booking !== undefined"
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormBooking.FeeAmount') }} »
                  </span>
                  <span style="font-size: 16px;">
                    {{ `${data.item.booking.fee_amount_show} ${$t('Baht')}` }}
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  v-if="data.item.event_type === 'booking' && data.item.booking !== undefined"
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormBooking.Booking By') }} »
                  </span>
                  <span style="font-size: 16px;">
                    <feather-icon icon="UserIcon" />
                    {{ data.item.booking.user_name }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <template #cell(contract)="data">
            <!-- Contract -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; background-color: #eeeeee;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormContract.Contract') }} »
                  </span>
                </b-list-group-item>
                <!-- Contract Status -->
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormContract.Status') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="(skin === 'dark')? `light-${isStatusVariant(data.item.contract_status)}` : `light-${isStatusVariant(data.item.contract_status)}` "
                      class="text-capitalize"
                    >
                      {{ $t(`FormContract.StatusMessage.${data.item.contract_status}`) }}
                    </b-badge>
                  </span>
                </b-list-group-item>
                <!-- Contract Date -->
                <b-list-group-item
                  v-if="data.item.contract_date !== undefined"
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormContract.Date') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <feather-icon icon="CalendarIcon" />
                    {{ data.item.contract_date.name }}
                  </span>
                  <span
                    class="ml-75"
                    style="font-size: 14px;"
                  >
                    <feather-icon icon="ClockIcon" />
                    {{ data.item.contract_time.name }}
                  </span>
                </b-list-group-item>
                <!-- Contract By -->
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormContract.By') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <feather-icon icon="UserIcon" />
                    {{ data.item.contract_user.name }}
                  </span>
                </b-list-group-item>
                <!-- Contract Type -->
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormContract.Type') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <feather-icon icon="CreditcardIcon" />
                    {{ (locale === 'th')? data.item.contract_type.name : data.item.contract_type.name_en }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Verify -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; background-color: #eeeeee;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormContract.Verify') }} »
                  </span>
                </b-list-group-item>
                <!-- Verify Date -->
                <b-list-group-item
                  v-if="data.item.verify_date !== undefined"
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormContract.Date') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <feather-icon icon="CalendarIcon" />
                    {{ data.item.verify_date.name }}
                  </span>
                  <span
                    class="ml-75"
                    style="font-size: 14px;"
                  >
                    <feather-icon icon="ClockIcon" />
                    {{ data.item.verify_time.name }}
                  </span>
                </b-list-group-item>
                <!-- Verify By -->
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormContract.By') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <feather-icon icon="UserIcon" />
                    {{ data.item.verify_user.name }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <div class="text-nowrap">
              <feather-icon
                v-if="$can('write', 'ModuleBooking.Contract')"
                :id="`contract-row-${data.item.id}-edit-icon`"
                icon="EditIcon"
                class="cursor-pointer mr-1"
                size="16"
                @click="editContractForm(data)"
              />
              <b-tooltip
                v-if="$can('write', 'ModuleBooking.Contract')"
                :title="`${$t('Edit')} ${$t('FormContract.Contract')}`"
                :target="`contract-row-${data.item.id}-edit-icon`"
                :delay="{ show: 2500, hide: 50 }"
                placement="left"
              />

              <feather-icon
                v-if="$can('read', 'ModuleBooking.Contract')"
                :id="`contract-row-${data.item.id}-view-icon`"
                icon="EyeIcon"
                size="16"
                class="cursor-pointer mr-1"
                @click="viewContractForm(data)"
              />
              <b-tooltip
                v-if="$can('read', 'ModuleBooking.Contract')"
                :title="`${$t('View')} ${$t('FormContract.Contract')}`"
                :target="`contract-row-${data.item.id}-view-icon`"
                :delay="{ show: 2500, hide: 50 }"
                placement="left"
              />

              <feather-icon
                v-if="$can('delete', 'ModuleBooking.Contract')"
                :id="`contract-row-${data.item.id}-delete-icon`"
                icon="TrashIcon"
                variant="light-success"
                size="16"
                class="cursor-pointer mr-1"
                @click="deleteContractForm(data)"
              />
              <b-tooltip
                v-if="$can('delete', 'ModuleBooking.Contract')"
                :title="`${$t('Delete')} ${$t('FormContract.Contract')}`"
                :target="`contract-row-${data.item.id}-delete-icon`"
                :delay="{ show: 2500, hide: 50 }"
                placement="right"
              />

            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">
                {{ $t('Showing {from} to {to} of {of} entries',
                      {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                }}
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalContracts"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>

      <!-- Form -->
      <contract-form
        v-model="shallShowContractFormModal"
        :contract-data="contractData"
        :contract-file="contractFile"
        :type-contract-form="typeContractForm"
        @contract-form-update="updateContractForm"
        @discard-contract-form="discardContractForm"
      />

    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput,
  BTable, BButton,
  BBadge, BPagination, BTooltip, BListGroup, BListGroupItem,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref,
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { $themeConfig } from '@themeConfig'
import ContractForm from './ContractForm.vue'
import ContractListFilters from './ContractListFilters.vue'
import tableContractList from './tableContractList'
import storeContract from './storeContract'

export default {
  components: {
    ContractListFilters,
    ContractForm,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BPagination,
    BTooltip,
    BListGroup,
    BListGroupItem,
    BButton,
    BOverlay,

    vSelect,
    FeatherIcon,
  },
  data() {
    return {
      showOverlay: false,
      contractData: {},
      contractFile: {},
      dialog: false,
      skin: store.state.appConfig.layout.skin,
      selected: [],
      checkSelected: false,
      collapsedFilter: true,
    }
  },
  Computed: {
    checkSelected() {
      return !this.checkSelected
    },
  },
  mounted() {
    this.getDefaultContract()
    this.locale = this.$i18n.locale
  },
  methods: {
    getDefaultContract() {
      this.showOverlay = true
      store
        .dispatch('store-contract/getDefaultContract', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.contractData = response.data
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = true
        })
    },
    addContractForm() {
      this.showOverlay = true
      store
        .dispatch('store-contract/getDefaultContract', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.contractData = response.data
          this.contractFile = {
            image: response.data.image,
          }
          this.typeContractForm = 'Add'
          this.shallShowContractFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    editContractForm(data) {
      this.showOverlay = true
      store
        .dispatch('store-contract/getContract', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.contractData = response.data
          this.contractFile = {
            image: response.data.image,
          }
          this.typeContractForm = 'Edit'
          this.shallShowContractFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    viewContractForm(data) {
      this.showOverlay = true
      store
        .dispatch('store-contract/getContract', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.contractData = response.data
          this.typeContractForm = 'View'
          this.shallShowContractFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    deleteContractForm(data) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('store-contract/deleteContract', { id: data.item.id })
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.refetchData()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response?.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response?.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error?.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
    updateContractForm() {
      this.refetchData()
    },
    discardContractForm() {
      // this.refetchData()
    },
    resetFilter() {
      this.searchQuery = ''
      this.eventCodeFilter = ''
      this.statusFilter = ''
      const filterList = JSON.parse(localStorage.getItem(`${$themeConfig.app.session}-filter-contract-list`))
      if (filterList !== null) {
        this.perPage = (filterList.perPage !== null) ? filterList.perPage : '5'
        this.currentPage = (filterList.page !== null) ? filterList.page : '1'
        this.sortBy = (filterList.sortBy !== null) ? filterList.sortBy : 'id'
        this.isSortDirDesc = (filterList.sortDesc !== null) ? filterList.sortDesc : 'DESC'
      }
    },
  },
  setup() {
    const shallShowContractFormModal = ref(false)
    const typeContractForm = ref('')

    // Register module
    if (!store.hasModule('store-contract')) store.registerModule('store-contract', storeContract)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('store-contract')) store.unregisterModule('store-contract')
    })

    const {
      getListContract,
      tableColumns,
      perPage,
      currentPage,
      totalContracts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      locale,
      refContractListTable,
      refetchData,

      isStatusVariant,
      textFirstUpper,
      numberFormat,
      numberFormatter,

      eventCodeFilter,
      descriptionFilter,
      statusFilter,

      statusOptions,
    } = tableContractList()

    const filterList = JSON.parse(localStorage.getItem(`${$themeConfig.app.session}-filter-contract-list`))
    if (filterList !== null) {
      searchQuery.value = (filterList.searchQuery !== null && filterList.searchQuery !== undefined) ? filterList.searchQuery : ''
      perPage.value = (filterList.perPage !== null && filterList.perPage !== undefined) ? filterList.perPage : ''
      currentPage.value = (filterList.page !== null && filterList.page !== undefined) ? filterList.page : ''
      sortBy.value = (filterList.sortBy !== null && filterList.sortBy !== undefined) ? filterList.sortBy : ''
      isSortDirDesc.value = (filterList.sortDesc !== null && filterList.sortDesc !== undefined) ? filterList.sortDesc : ''
      eventCodeFilter.value = (filterList.eventCode !== null && filterList.eventCode !== undefined) ? filterList.eventCode : ''
      descriptionFilter.value = (filterList.description !== null && filterList.description !== undefined) ? filterList.description : ''
      statusFilter.value = (filterList.status !== null && filterList.status !== undefined) ? filterList.status : ''
    }

    return {
      shallShowContractFormModal,
      typeContractForm,

      getListContract,
      tableColumns,
      perPage,
      currentPage,
      totalContracts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      locale,
      refContractListTable,
      refetchData,

      isStatusVariant,
      textFirstUpper,
      numberFormat,
      numberFormatter,

      eventCodeFilter,
      descriptionFilter,
      statusFilter,

      statusOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.contract-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
@import '~@core/scss/base/components/variables';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }
  .top-bar-title-bg{
    background-color: $theme-dark-modal-header-bg !important;
  }
}
.top-bar-title-bg{
  background-color: $theme-light-modal-header-bg !important;
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
</style>
