<template>
  <div>
    <b-col class="p-0">
      <b-card-actions
        ref="refFilter"
        :title="$t('Filters')"
        action-collapse
        :collapsed="collapsedFilter"
      >
        <b-card-body class="p-0">

          <b-row>
            <!-- Event Code -->
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('FormContract.EventCode') }}</label>
              <b-form-input
                :value="eventCodeFilter"
                class="d-inline-block mr-1"
                :reduce="val => val.value"
                @input="(val) => $emit('update:eventCodeFilter', val)"
              />
            </b-col>
            <!-- Description -->
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('FormContract.Description') }}</label>
              <b-form-input
                :value="descriptionFilter"
                class="d-inline-block mr-1"
                :reduce="val => val.value"
                @input="(val) => $emit('update:descriptionFilter', val)"
              />
            </b-col>
            <!-- Status -->
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('Status') }}</label>
              <div class="d-flex">
                <v-select
                  :value="statusFilter"
                  :options="statusOptions"
                  class="w-100"
                  :clearable="false"
                  :reduce="val => val.value"
                  @input="(val) => $emit('update:statusFilter', val)"
                />
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <!-- Button -->
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2"
              style="text-align: right;"
            >
              <div>
                <b-button
                  variant="outline-primary"
                  @click="resetFilter"
                >
                  {{ `${$t('Reset Filter')}` }}
                </b-button>
                &nbsp;
                <b-button
                  variant="info"
                  @click="onCollapsed"
                >
                  {{ `${$t('Collapse')}` }}
                </b-button>
              </div>
            </b-col>
          </b-row>

        </b-card-body>
      </b-card-actions>
    </b-col>
  </div>
</template>

<script>
import {
  BCardBody, BRow, BCol,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCardBody,
    BCardActions,
    BFormInput,
    BButton,
    vSelect,
  },
  props: {
    collapsedFilter: {
      type: Boolean,
      default: false,
    },
    eventCodeFilter: {
      type: String,
      default: '',
    },
    descriptionFilter: {
      type: String,
      default: '',
    },
    statusFilter: {
      type: String,
      default: '',
    },
    statusOptions: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    resetFilter() {
      this.$emit('reset-filter')
    },
    onCollapsed() {
      this.$refs.refFilter.triggerCollapse()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.card {
  .card-title {
    font-size: 1.07rem;
  }
  .card-header {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
</style>
