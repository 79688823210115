import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t, t2 } from '@/@core/libs/i18n/utils'
import { $themeConfig } from '@themeConfig'

export default function tableContractList() {
  // Use toast
  const toast = useToast()

  const refContractListTable = ref(null)
  const lineOa = computed(() => store.state.lineapi.lineOa)

  const msgList = {
    errorfetch: t2('Error fetching {module} list', { module: t('TitleBooking.Contract') }),
    id: `${t('ID')} » ${t('FormContract.Type')} » ${t('FormContract.Event')}`,
    contract: `${t('FormContract.Contract')}`,
    actions: t('Actions'),
  }

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true, label: msgList.id },
    { key: 'contract', sortable: true, label: msgList.contract },
    { key: 'actions', label: msgList.actions },
  ]

  const perPage = ref(10)
  const totalContracts = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [2, 5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const locale = ref('th')
  const eventCodeFilter = ref('')
  const descriptionFilter = ref('')
  const statusFilter = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refContractListTable.value ? refContractListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalContracts.value,
    }
  })

  const refetchData = () => {
    refContractListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, locale, eventCodeFilter, descriptionFilter, statusFilter], () => {
    refetchData()
  })

  const getListContract = (ctx, callback) => {
    const { id } = JSON.parse(lineOa.value)
    const params = {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      language: locale.value,
      eventCode: eventCodeFilter.value,
      description: descriptionFilter.value,
      status: statusFilter.value,
      lineOaId: id,
    }
    store
      .dispatch('store-contract/getListContract', params)
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalContracts.value = total
        localStorage.setItem(`${$themeConfig.app.session}-filter-contract-list`, JSON.stringify(params))
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgList.errorfetch,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  const isStatusVariant = status => {
    if (status === 'wait_contract') return 'info'
    if (status === 'canceled') return 'danger'
    if (status === 'contracted') return 'success'
    return 'secondary'
  }

  const textFirstUpper = text => text.slice(0, 1).toUpperCase() + text.slice(1, text.length)

  const numberFormat = value => parseFloat(value).toFixed(2)

  const numberFormatter = (value, decimal) => {
    if (value === '') return ''
    return parseFloat(parseFloat(value).toFixed(decimal)).toLocaleString(
      'en-US',
      {
        minimumFractionDigits: decimal,
        useGrouping: true,
      },
    )
  }

  const statusOptions = [
    {
      label: t('All'),
      value: '',
    },
    {
      label: t('FormContract.StatusMessage.wait_contract'),
      value: 'wait_contract',
    },
    {
      label: t('FormContract.StatusMessage.contracted'),
      value: 'contracted',
    },
    {
      label: t('FormContract.StatusMessage.expired'),
      value: 'expired',
    },
    {
      label: t('FormContract.StatusMessage.canceled'),
      value: 'canceled',
    },
  ]

  const eventTypeVariant = type => {
    if (type === 'booking') return 'info'
    if (type === 'renting') return 'success'
    return 'secondary'
  }

  return {
    getListContract,
    tableColumns,
    perPage,
    currentPage,
    totalContracts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    locale,
    refContractListTable,

    refetchData,
    isStatusVariant,
    textFirstUpper,
    numberFormat,
    numberFormatter,

    eventCodeFilter,
    descriptionFilter,
    statusFilter,

    statusOptions,
    eventTypeVariant,
  }
}
