import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultContract(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_default_contract', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListContract(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_list_contract', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getContract(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_contract', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addContract(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/add_contract', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editContract(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/edit_contract', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteContract(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/delete_contract', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImageContractImage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/booking/upload_image_contract_image/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteImageContractImage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/delete_image_contract_image', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkContract(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/check_contract', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
